import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Jumbotron } from "./migration";

const Bachelor = () => {
  let img = [
    { 
      img: require("../../editable-stuff/Photography.jpg"), 
      label: "Photography at ceremonies",
      paragraph: "The ceremony of the day of the Faculty at year 2021 was one of my favorite events to take photos at. I was able to capture the best moments of the day and share them with the students and professors."
    },
    { 
      img: require("../../editable-stuff/FLA-Teaching.jpg"), 
      label: "Teaching as the TA of Formal Languages and Automata Theory", 
      paragraph: "I've always enjoyed the teaching of pumping lemma and its applications in the theory of computation. I've been the TA of this course for 5 semesters and I've always tried to make the students understand the concepts of this course as best as possible." 
    },
  ];
  return (
    <Jumbotron
      id="Bachelor"
      className="m-0"
      style={{ backgroundColor: "white" }}
    >
      <h2 className="display-4 pb-1 text-center">Bachelor of Engineering in Computer Engineering</h2>
      <h6 className="display-6 pb-1 text-center">Shahid Beheshti University in Tehran, Iran from 2019</h6>
      <h6 className="display-6 pb-5 text-center">GPA: 18.02 out of 20</h6>
      <div className="row">
        <div className="col-md-5">
          <p className="lead">I'm thrilled to have attended a university that provided me with the opportunity to excel in computer programming, mathematics, and computer science. My passion for cryptocurrencies and blockchain technology, along with the knowledge and skills I've developed, led me to work extensively in the university's blockchain labs. During this time, I co-authored a submitted paper titled "Preserving Urban Traffic Data Privacy Using Blockchain-based Vehicular Ad-hoc Networks." Additionally, my enthusiasm for this field inspired me to explore the effects and usage of reinforcement learning in cryptocurrency investment funds for my thesis. <br/></p>
          <h2 className="display-7">Courses I Have Been a TA For:</h2>
        <ul className="lead">
            <li><b>CryptoCurrencies and Blockchain</b></li>
            <li><b>Algorithms Design</b></li>
            <li><b>Computer Architecture</b></li>
            <li><b>Discrete Math</b></li>
            <li><b>Signal and Systems</b></li>
            <li><b>Advanced Programming</b></li>
            <li><b>Introduction to Programming</b></li>
            <li><b>Formal Languages and Automata Theory</b></li>
        </ul>
        <p className="lead">I'm also glad that I've been part of a community where every individual has their unique perspective on life and career. <br/>I've participated in many voluntary activities, such as taking photographs at ceremonies and assisting the Computer Science Faculty Science Club in organizing and executing the best possible events.</p>
        </div>
        <div className="col-md-7">
          <Carousel>
            {img.map((value, index) => {
              return (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={value.img}
                    alt="First slide"
                    width="300"
                    height="800"
                    style={{objectFit: "cover"}}
                  />
                  <Carousel.Caption>
                    <h3>{value.label}</h3>
                    <p>
                      {value.paragraph}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
    </Jumbotron>
  );
};

export default Bachelor;
