import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Jumbotron } from "./migration";

const Bachelor = () => {
  let img = [
    { 
      img: require("../../editable-stuff/quera-library.jpg"), 
      label: "Me at the quera's library",
    }
    //,{ 
    //  img: require("../../editable-stuff/calligraphy.jpg"), 
    //  label: "Calligraphy I did on board at Quear's office", 
    //},
  ];
  return (
    <Jumbotron
      id="Work"
      className="m-0"
      style={{ backgroundColor: "white" }}
    >
      <h2 className="display-4 pb-5 text-center">Blockchain track owner at Quera as a related work experience</h2>
      <div className="row">
        <div className="col-md-5">
          <p className="lead">During my time at Quera, I served as the track owner for the blockchain, a role that allowed me to delve deep into my beloved field of study: blockchain and cryptocurrencies. Alongside this, I also owned a boot camp, which presented its own set of challenges. I was responsible for sourcing qualified instructors, crafting engaging headlines, and ensuring the program's curriculum was in line with my experiences as a teaching assistant. To enhance the learning experience, I curated a team of mentors and developed homework assignments that catered to the evolving world of blockchain technology. This experience was not just a job but a passionate journey closely intertwined with my academic interests.</p>
       </div>
        <div className="col-md-7">
          <Carousel>
            {img.map((value, index) => {
              return (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100"
                    src={value.img}
                    alt="First slide"
                    width="300"
                    height="400"
                    style={{objectFit: "cover"}}
                  />
                  <Carousel.Caption>
                    <h3>{value.label}</h3>
                    <p>
                      {value.paragraph}
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
    </Jumbotron>
  );
};

export default Bachelor;
