// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Parsa",
  middleName: "",
  lastName: "Noori",
  message: "Curious and passionate about the impact of blockchain and cryptocurrencies on the world.",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/parsanoori",
    },
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/_.p.noori._",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/parsa-noori-94319a1a7",
    }
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/parsa.jpeg"),
  imageSize: 375,
  message:
    "My name is Parsa Noori. I'm a graduated student from Shahid Beheshti University. I'm extremely interested in blockchain, cryptocurrencies, and the philosophy behind them. As a liberalist, I believe that blockchain will provide the world with the opportunity to achieve the necessary values. I've worked in the blockchain labs of our university and submitted a paper on the use of blockchain for privacy in VANET systems. I started learning about Bitcoin and other cryptocurrencies before beginning my undergraduate program, and I have been looking forward to taking related courses throughout my university years.",
  resume: require("../editable-stuff/Resume.pdf"),
  CV: require("../editable-stuff/CV.pdf"),
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: true,
  heading: "Projects",
  gitHubUsername: "parsanoori", //i.e."johnDoe12Gh"
  reposLength: 0,
  specificRepos: ["PCoin", "Simple-Blockchain", "fft-codesign", "rsa-codesign"],
};

// Leadership SECTION
//const leadership = {
//  show: false,
//  heading: "Bachelor of Science in Computer Engineering",
//  message:
//    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
//  images: [
//    { 
//      img: require("../editable-stuff/hashirshoaeb.png"), 
//      label: "First slide label", 
//      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
//    },
//    { 
//      img: require("../editable-stuff/hashirshoaeb.png"), 
//      label: "Second slide label", 
//      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
//    },
//  ],
//  imageSize: {
//    width:"615",
//    height:"450"
//  }
//};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "Python", value: 90 },
    { name: "Solidity", value: 80 },
    { name: "Cryptocurrencies", value: 80 },
    { name: "Cryptography", value: 70 },
    { name: "Discrete Mathematics", value: 90 },
    { name: "Formal Languages", value: 90 },
    { name: "SQL", value: 75 },
    { name: "C++", value: 85 },
    { name: "Golang", value: 70 },
    { name: "Data Structures", value: 85 },
    { name: "Algorithms", value: 75 },
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 80 },
    { name: "Persistence", value: 90 },
    { name: "Positivity", value: 75 },
    { name: "Adaptability", value: 85 },
    { name: "Problem Solving", value: 85 },
    { name: "Creativity", value: 90 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "I'm currently looking forward to continue my journey in academia and research by pursuing a graduate degree in the field of computer science. If you want to get in touch with me for any purposes please don't hesitate to send me an email",
  email: "parsanoori79@gmail.com",
};

const experiences = {
  show: false,
  heading: "Educations",
  data: [
    {
      role: 'High School Diploma',
      companylogo: require('../editable-stuff/aehighschool.jpeg'),
      date: '2016 – 2019',
    },
    {
      role: 'Bachelor of Science in Computer Engineering',
      companylogo: require('../editable-stuff/sbu-logo.png'),
      date: '2016 – 2019',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, getInTouch, experiences };
