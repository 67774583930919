import React from "react";
//import useResizeObserver from "../hooks/useResizeObserver";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { mainBody, about } from "../editable-stuff/config.js";
import { NavLink } from "./home/migration";

const Navigation = React.forwardRef((props, ref) => {
  // const { showBlog, FirstName } = config;
  // const [isTop, setIsTop] = useState(true);
  // const [scrollPosition, setScrollPosition] = useState(0);
  const navbarMenuRef = React.useRef();
  //const navbarDimensions = useResizeObserver(navbarMenuRef);
  //const navBottom = navbarDimensions ? navbarDimensions.bottom : 0;
  //useScrollPosition(
  //  ({ prevPos, currPos }) => {
  //    if (!navbarDimensions) return;
  //    currPos.y + ref.current.offsetTop - navbarDimensions.bottom > 5
  //      ? setIsTop(true)
  //      : setIsTop(false);
  //    setScrollPosition(currPos.y);
  //  },
  //  [navBottom]
  //);

  //React.useEffect(() => {
  //  if (!navbarDimensions) return;
  //  navBottom - scrollPosition >= ref.current.offsetTop
  //    ? setIsTop(false)
  //    : setIsTop(true);
  //}, [navBottom, navbarDimensions, ref, scrollPosition]);

  return (
    <Navbar
      ref={navbarMenuRef}
      className={`px-3 fixed-top  navbar-white
        }`}
      expand="lg"
    >
      <Navbar.Brand className="navbar-brand" href={process.env.PUBLIC_URL + "/#home"}>
        {` It's ${mainBody.firstName} `}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggler" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="navbar-nav mr-auto">
          {/* {
            <NavLink className="nav-item lead">
              <Link to={process.env.PUBLIC_URL + "/blog"}>Blog</Link>
            </NavLink>
          } */}
          <NavLink
              className="nav-item lead"
              href={process.env.PUBLIC_URL + "/#aboutme"}
            >
              About
          </NavLink>
          <NavLink
            className="nav-item lead"
            href={about.resume}
            target="_blank"
            rel="noreferrer noopener"
          >
            Resume
          </NavLink>
          <NavLink
            className="nav-item lead"
            href={about.CV}
            target="_blank"
            rel="noreferrer noopener"
          >
            CV
          </NavLink>
          <NavLink
              href={process.env.PUBLIC_URL + "/#Bachelor"}
            >
              Bachelor
            </NavLink>
          <NavLink
              href={process.env.PUBLIC_URL + "/#HighSchool"}
            >
              High School
            </NavLink>
          <NavLink
              href={process.env.PUBLIC_URL + "/#Work"}
            >
              Work
            </NavLink>
          <NavLink
              href={process.env.PUBLIC_URL + "/#projects"}
            >
              Projects
            </NavLink>
          <NavLink
              className="nav-item lead"
              href={process.env.PUBLIC_URL + "/#skills"}
            >
              Skills
          </NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
});

export default Navigation;
