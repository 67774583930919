import React from "react";
import { Jumbotron } from "./migration";

const HighSchool = () => {
  return (
    <Jumbotron
      id="HighSchool"
      className="m-0"
      style={{ backgroundColor: "white" }}
    >
      <h2 className="display-4 pb-1 text-center">High School Diploma of Mathematics and Physics</h2>
      <h6 className="display-6 pb-1 text-center">Atomic Energy HighSchool in Tehran, Iran from 2016 to 2019</h6>
      <h6 className="display-6 pb-5 text-center">GPA: 19.58 out of 20</h6>
      <div className="row">
        <div className="text-center">
          <p className="lead">I had the privilege of attending Atomic Energy High School, renowned as the best in Iran for its academic excellence in Olympiad and National University Entrance Exams. During my time at the high school, I took part in the Physics Olympiad course and successfully passed the entrance exam on two occasions. Additionally, I demonstrated my proficiency in mathematics by earning acceptance into the Math Olympiad entry-level exam. These experiences sharpened my problem-solving skills and critical thinking abilities. Finally, my dedication to my studies paid off when I achieved an impressive rank of 628 in the highly competitive university entrance exam, opening doors to a promising future in academia and research.</p>
        </div>
      </div>
    </Jumbotron>
  );
};

export default HighSchool;
